<template>
	<div class="page" ref="page">
		<div class="swiperBox">
			<swiper style="width:100%;height: 100%;overflow: hidden;position: relative;" ref="mySwiper" :options="swiperOptions">
				<swiper-slide v-for="(item,index) in petBanner" :key="index">
					<video
						v-if="item.url.substr(item.url.length-3)==='mp4'"
						controls
						style="width:100%;height: 100%;"
						:poster="item.thumbnail"
						@touchend="toSwiperPage(index)"
						@touchmove="touchMoveState = true"
					>
						<source :src="item.url" type="video/mp4">
						Your browser does not support the video tag.
					</video>
					<img v-else @click="toSwiperPage(index)" style="width:100%;height: 100%;" :src="item.url"/>
				</swiper-slide>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
		</div>
		<div class="tagLine">
			<div class="tagItem">
				<img class="tagImg" src="../../assets/img/icon1.png" />
				<div class="tagText">一宠一芯</div>
			</div>
			<div class="tagItem">
				<img class="tagImg" src="../../assets/img/icon2.png" />
				<div class="tagText">宠物证书</div>
			</div>
			<div class="tagItem">
				<img class="tagImg" src="../../assets/img/icon3.png" />
				<div class="tagText">百天医疗</div>
			</div>
			<div class="tagItem">
				<img class="tagImg" src="../../assets/img/icon4.png" />
				<div class="tagText">宠物疫苗</div>
			</div>
		</div>
		<div class="panel">
			<div class="tit">基本信息</div>
			<div class="descBox">
				<div class="descItem">{{goodsDetail.categoryInfo.name}}</div>
				<div class="descItem">{{goodsDetail.petColor}}</div>
				<div class="descItem">{{goodsDetail.petSex}}</div>
				<div class="descItem">{{goodsDetail.age}}</div>
				<div class="descItem">
					<span v-if="goodsDetail.vaccineNeedle===1">第一针</span>
					<span v-else-if="goodsDetail.vaccineNeedle===2">第二针</span>
					<span v-else-if="goodsDetail.vaccineNeedle===3">第三针</span>
					<span v-else-if="goodsDetail.vaccineNeedle===4">第四针</span>
					<span v-else-if="goodsDetail.vaccineNeedle===5">第五针</span>
					<span v-else>无疫苗</span>
				</div>			 
				<div class="descItem" v-for="item in goodsDetail.customFeatures" :key="item">
					<span> {{item}}</span>
				</div>
			</div>
		</div>
		<div class="panel" style="margin-top:.16rem;" v-if="!$public.isNull(detailImgList)">
			<div class="tit">详情</div>
			<div class="partBox">
				<div class="partItem" >
					<img v-if="!$public.isNull(detailImgList)" @click="$router.push({path:'/hsPet/imagePage?type=header&list='+JSON.stringify(detailImgList)})" style="width:100%;height: 100%;" :src="detailImgList[0]"/>
					<img v-else style="width:100%;height: 100%;" src="../../assets/img/noPicture.png"/>
					<div class="mask">详情图</div>
				</div>
			</div>
		</div>
		<div class="panel" style="margin-top:.16rem;">
			<div class="subTitle">- 详细资料 -</div>
			<div class="label">{{goodsDetail.insideName}}</div>
			<div class="label1">{{goodsDetail.categoryInfo.name}} | {{goodsDetail.petColor}}</div>
			<div class="value">
				<div class="valueBox">
					<div class="valueItem">
						<div class="col1" style="margin-right: .2rem;">生日</div>
						<div class="col2">{{$public.FTime(goodsDetail.bronDate,'YYYY-MM-DD')}}</div>
					</div>
					<div class="valueItem">
						<div class="col1" style="margin-right: .2rem;">芯片</div>
						<div class="col2">{{goodsDetail.epromNo}}</div>
					</div>
					<div class="valueItem" v-if="!$public.isNull(goodsDetail.petGrade)">
						<div class="col1" style="margin-right: .2rem;">品级</div>
						<div class="col2">{{goodsDetail.petGrade.name}}</div>
					</div>
					<div class="valueItem">
						<div class="col1" style="margin-right: .2rem;">颜色</div>
						<div class="col2">{{goodsDetail.petColor}}</div>
					</div>
					<div class="valueItem">
						<div class="col1" style="margin-right: .2rem;">简介</div>
						<div class="col2" style="width:5.5rem;">{{goodsDetail.petDescribe}}</div>
					</div>
				</div>
			</div>
			<div class="label" style="margin-top: .3rem;">疫苗接种情况</div>
			<div class="label1">疫苗医疗凭证号：{{$public.isNull(goodsDetail.vaccinesNo)?'无疫苗':goodsDetail.vaccinesNo}}</div>
			<div class="value" v-if="!$public.isNull(goodsDetail.vaccinesInfo)">
				<div class="valueBox">
					<div class="valueItem" v-for="(item,index) in goodsDetail.vaccinesInfo" :key="index">
						<div class="col1">{{item.planName}}</div>
						<div class="col2">{{item.medicalName}}</div>
						<!-- <div class="col3">{{$public.FTime(item.planTime,'YYYY-MM-DD')}}  </div> -->
					</div>
				</div>
			</div>		
		</div>
		<!-- <div class="panel" style="margin-top:.16rem;">
			<div class="subTitle">- 猜你喜欢 -</div>
			<div class="recommendList">
				<div class="recommendItem" @click="toGoodsDetail(item)" v-for="(item,index) in tuijianList" :key="index">
					<img class="recommendImg" :src="item.avatarPic"/>
					<div class="recommendName">{{item.insideName}}</div>
					<div class="recommendDescBox">
						<div class="recommendDescItem">{{item.petColor}}</div>
						<div class="recommendDescItem">{{item.age}}</div>
						<div class="recommendDescItem">{{item.petSex}}</div>
					</div>
				</div>
			</div>
		</div> -->
		<div class="panel" style="height: 2rem;"></div>
		<div class="submitBtn" @click="submit">提交联系方式</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				swiperOptions: {
					pagination: {
						el:'.swiper-pagination',
						type: 'fraction'
					},
				},
				
				goodsDetail:{
					categoryInfo:{},
					petGrade:{},
				},
				petBanner:[],
				detailImgList:[],
				// faceImages:[],
				// sidewaysImages:[],
				// distanceImages:[],
				
				tuijianList:[],
				
				uId:'',
				uName:'',
				petId:'',
				
				touchMoveState:false,
			}
		},
		computed:{
			
		},
		mounted(){
			this.uId = this.$public.queryString('uId')
			this.uName = this.$public.queryString('uName')
			this.petId = this.$public.queryString('petId')
			
			this.getGoodsDetail()
		},
		methods:{
			toSwiperPage(index){
				if(this.touchMoveState===true){
					this.touchMoveState=false
				}else{
					sessionStorage.setItem('petBanner',JSON.stringify(this.petBanner))
					this.$router.push({path:'/hsPet/swiperPage?index='+index})
				}
				
			},
			toGoodsDetail(item){
				this.petId = item._id
				this.getGoodsDetail();
				this.$refs.page.scrollTop=0
			},
			submit(){
				sessionStorage.setItem('petIntention',JSON.stringify({
					uId:this.uId,
					uName:this.uName,
					petId:this.petId,
					applyType:this.goodsDetail.applyType,
					epromNo:this.goodsDetail.epromNo,
					categoryInfo:this.goodsDetail.categoryInfo,
					breedInfo:this.goodsDetail.breedInfo,
					petName:this.goodsDetail.insideName,
					petColor:this.goodsDetail.petColor,
					petSex:this.goodsDetail.petSex,
				}))
				this.$router.push({path:'/hsPet/contactUs'})
			},
			// 获取列表
			getGoodsDetail(){
				let params = {
					petId: this.petId ,
				}
				this.$http.get(process.env.VUE_APP_Url_baseData + '/api/product/pet/detail/info', params).then(res => {
					if (res.code == 0) {
						let d = res.data
						if(d.customFeatures==null || d.customFeatures==undefined){
							d.customFeatures=[]
						}
						this.goodsDetail = d;
						// 宠物轮播
						this.petBanner = []
						if(d.bannerVideo){
							this.petBanner.push(d.bannerVideo)
						}
						for(let i=0;i<d.bannerList.length;i++){
							this.petBanner.push(d.bannerList[i])
						}
						// // 正脸轮播
						this.detailImgList = d.detailImgList
						// // 侧身轮播
						// this.sidewaysImages = d.sidewaysImages
						// // 远景轮播
						// this.distanceImages = d.distanceImages
						
						// this.getTuijian();
					}
				})
			},
			// 推荐
			// getTuijian(){
			// 	let params = {
			// 		petId: this.petId,
			// 	}
			// 	this.$http.get(process.env.VUE_APP_Url_baseData + '/api/Recommend/Pet/list', params).then(res => {
			// 		if (res.code == 0) {
			// 			let d = res.data
			// 			this.tuijianList = d;
			// 		}
			// 	})
			// },
		},
	};
</script>
<style lang="scss" scoped>
.page{
	width:100%;height: 100%;box-sizing: border-box;overflow: auto;
	.swiperBox{
		width:100%;height: 4.46rem;background-color: #f5f5f5;
		.swiper-pagination{
			box-sizing: border-box;width:.6rem;background-color:rgba(150,150,150,.5);color:#fff;right:.15rem;left:auto;bottom:.15rem;border-radius: .05rem;padding:.05rem;
		}
		
	}
	.tagLine{
		width:100%;height: .72rem;border-bottom:.02rem solid #f0f0f2;display: flex;justify-content: space-around;background-color: #fff;
		.tagItem{
			display: flex;justify-content: center;align-items: center;
			.tagImg{
				width:.32rem;height: .32rem;margin-right: .08rem;
			}
			.tagText{
				font-size:.24rem;color:#999;
			}
		}
	}
	.panel{
		padding:0 .24rem .3rem;background-color: #fff;
		.tit{
			font-size: .32rem;color:#333;padding:.3rem 0;display: flex;font-weight: bold;
		}
		.descBox{
			background-color: #f7f7fa;border:.02rem solid #f0f0f2;border-radius: .06rem;display: flex;padding:.24rem .24rem 0;flex-wrap: wrap;
			.descItem{
				font-size: .24rem;color: #666;border:.01rem solid #b5b5b5;border-radius: .06rem;line-height: .48rem;padding:0 .2rem;margin:0 .24rem .24rem 0;
			}
		}
		.partBox{
			display: flex;justify-content: space-between;
			.partItem{
				width:2.2rem;height: 2.2rem;position: relative;
				.mask{
					position: absolute;bottom:0;width:100%;line-height: .48rem;background-color: rgba(0,0,0,.2);color:#fff;
				}
			}
			
		}
		.subTitle{
			font-size: .24rem;color:#999;padding:.32rem 0;
		}
		.label{
			font-size: .28rem;color:#1e1e20;display: flex;font-weight: bold;padding:0 0 .05rem .1rem;
		}
		.label1{
			font-size: .24rem;color:#1e1e20;display: flex;font-weight: bold;padding:0 0 .05rem .1rem;
		}
		.value{
			.valueBox{
				padding:.26rem .25rem .05rem;background-color: #f7f9ff;border:.02rem solid #ebecf1;border-radius: .08rem;
				.valueItem{
					display: flex;margin-bottom:.2rem;line-height: .30rem;
					.col1{
						font-size: .24rem;color:#95989f;margin-right: .05rem;width:1rem;text-align: left;
					}
					.col2{
						font-size: .26rem;color:#3D3E42;display: flex;width:4rem;text-align: start;
					}
					.col3{
						font-size: .22rem;color:#999;width:1.3rem;
					}
				}
			}
		}
		.recommendList{
			display: flex;flex-wrap: wrap;justify-content: space-between;
			.recommendItem{
				padding-bottom:.4rem;
				.recommendImg{
					width:3.4rem;height: 3.4rem;
				}
				.recommendName{
					font-size: .28rem;color:#333;display: flex;margin:.06rem 0;
				}
				.recommendDescBox{
					display: flex;flex-wrap: wrap;
					.recommendDescItem{
						border:.01rem solid #b5b5b5;border-radius: .06rem;margin-right: .1rem;padding:.06rem .1rem;
					}
				}
			}
		}
	}
	.submitBtn{
		width:6.86rem;line-height: .92rem;background-color: #1b8dff;border-radius: .06rem;color:#fff;font-size: .28rem;position: fixed;bottom:.3rem;left:.3rem;
	}
}
</style>
